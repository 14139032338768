import BeforListBtn from "components/sub/BeforListBtn";
import OneBoard from "components/sub/OneBoard";

const ApplicationDetail = () => {
  return (
    <div className="relative w-full h-fit py-7 lg:py-14">
      <OneBoard />
      <BeforListBtn link="/application" />
    </div>
  );
};

export default ApplicationDetail;
