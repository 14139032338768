import { IconDiscountCheckFilled } from "@tabler/icons-react";
import { dateFormat } from "const/dateFormat";
import { Inquiry } from "const/table";

const OneInquiry = ({ data }: { data: Inquiry }) => {
  console.log(data);
  return (
    <div className="relative w-full h-full">
      <div className="absolute -right-8 -top-16 w-fit h-fit z-10 text-point">
        <IconDiscountCheckFilled size={50} />
      </div>
      <div className="relative w-full h-full grid lg:grid-cols-2 gap-1 text-center mb-10">
        <InquiryBaseCard title="이름" contents={data.name} />
        <InquiryBaseCard
          title="전화번호"
          contents={
            data.phone.slice(0, 3) +
            "-" +
            data.phone.slice(3, 7) +
            "-" +
            data.phone.slice(7)
          }
        />
        <InquiryBaseCard title="이메일" contents={data.email || ""} />
        <InquiryBaseCard title="작성일" contents={dateFormat(data.createdAt)} />

        <div className="lg:col-span-2 flex items-center">
          <div className="relative min-w-[80px] lg:min-w-[150px] bg-light py-2">
            <p className="lg:text-base">제목</p>
          </div>
          <div className="relative w-full py-2 text-left px-3 border-b border-light">
            <p className="lg:text-lg">{data.title}</p>
          </div>
        </div>
        <div className="lg:col-span-2 lg:row-span-2 flex items-start min-h-[300px]">
          <div className="relative min-w-[80px] lg:min-w-[150px] h-full bg-light py-2">
            <p className="lg:text-base">내용</p>
          </div>
          <div className="relative w-full py-2 px-3 text-left border-b border-light">
            <p className="lg:text-lg">{data.contents}</p>
          </div>
        </div>
        {/* <InquiryBaseCard title="아이피주소" contents={data.ip || ""} />
        <InquiryBaseCard title="접속기기" contents={data.device || ""} /> */}
      </div>
      <div
        onClick={() => (window.location.href = "/admin/inquiry")}
        className="relative w-fit h-fit mx-auto border flex justify-center items-center py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
      >
        <p>목록으로</p>
      </div>
    </div>
  );
};

export default OneInquiry;

const InquiryBaseCard = ({
  title,
  contents,
}: {
  title: string;
  contents: string;
}) => {
  return (
    <div className="flex items-center">
      <div className="relative min-w-[80px] lg:min-w-[150px] bg-light py-2">
        <p className="lg:text-base">{title}</p>
      </div>
      <div className="relative w-full py-2 border-b border-light">
        <p className="lg:text-lg">{contents}</p>
      </div>
    </div>
  );
};
