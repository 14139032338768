import AdminApplicationList from "components/admin/application/ApplicationList";

const Application = () => {
  return (
    <div>
      <AdminApplicationList />
    </div>
  );
};

export default Application;
