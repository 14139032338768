import axios from "axios";
import QnAList from "components/admin/qna/QnAList";
import { useEffect, useState } from "react";

const QnA = () => {
  const [data, setData] = useState();

  useEffect(() => {
    axios.get(`/api/qna/list`).then((res) => setData(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{data && <QnAList qna={data} />}</div>;
};

export default QnA;
