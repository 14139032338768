import { Link, useParams } from "react-router-dom";

import axios from "axios";
import { notifications } from "@mantine/notifications";

import { JoinNotice } from "const/table";
import { IconCheck } from "@tabler/icons-react";
import { dateFormat } from "const/dateFormat";

const OneBoard = ({ board }: { board: JoinNotice }) => {
  const params = useParams();

  const DeleteBoard = async () => {
    if (!window.confirm("정말 해당 게시물을 삭제하시겠습니까?")) {
      return false;
    }

    await axios.delete(`/api/board/${params.id}`).then((res) => {
      if (res.data.code === "0000") {
        notifications.show({
          title: "게시글 삭제 완료",
          message: "게시글 삭제가 완료되었습니다. 메인화면으로 이동합니다.",
          autoClose: 2000,
          color: "blue",
          icon: <IconCheck style={{ width: "rem(18)", height: "rem(18)" }} />,
        });
        setTimeout(() => {
          window.location.href = "/admin/board";
        }, 1000);
      }
    });
  };

  return (
    <div className="relative w-full h-full">
      <div className="relative w-full grid gap-5 lg:px-5 border-b pt-4 pb-2 lg:pb-7">
        <div className="relative w-fit h-fit border border-font rounded-full py-0.5 px-3">
          <p className="text-xs lg:text-sm text-neutral-500">
            {board.category.name}
          </p>
        </div>
        <h5 className="lg:text-4xl">{board.title}</h5>
        <small className="text-neutral-500">
          {dateFormat(board.createdAt)}
        </small>
      </div>
      {board.startDate && (
        <div className="relative w-full flex gap-5 py-5 lg:p-5">
          <p className="hidden lg:block">
            <b>신청기간</b>
          </p>
          <p>
            <b>{dateFormat(board.startDate)}</b>
          </p>
          <p>
            <b>~</b>
          </p>
          <p>
            <b>{dateFormat(board.endDate!)}</b>
          </p>
        </div>
      )}
      <div className="boardContents lg:px-5 py-4 lg:py-7 border-b">
        <div
          className="min-h-[300px] lg:min-h-[550px]"
          dangerouslySetInnerHTML={{
            __html: board.contents,
          }}
        ></div>
        {board.files[0] && (
          <a
            href={`/api/board/download?id=${board.files[0].id}`}
            className="relative w-full h-fit"
            download
          >
            <div className="relative w-full h-fit border p-3 shadow-sm rounded-sm lg:hover:shadow-md">
              <p className="lg:text-lg">{board.files[0].originalName}</p>
              <small className="lg:text-sm text-neutral-500">
                DATE : {dateFormat(board.createdAt)}
              </small>
            </div>
          </a>
        )}
      </div>
      <div className="flex justify-center items-center gap-5 mt-7 lg:mt-14 ">
        <div
          onClick={() => (window.location.href = `/admin/update/${board.id}`)}
          className="relative w-fit h-fit border flex justify-center items-center py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
        >
          <p>수정하기</p>
        </div>
        <div
          onClick={DeleteBoard}
          className="relative w-fit h-fit border flex justify-center items-center py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
        >
          <p>삭제하기</p>
        </div>
      </div>
    </div>
  );
};

export default OneBoard;
