import { useEffect, useRef, useState } from 'react';

import { IconUserCircle, IconLayoutDashboard, IconBellRinging } from '@tabler/icons-react';

import { UnstyledButton, Menu, Burger } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';

import { ADMIN_NAV_LIST } from 'const/link';
import { useLocation } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    user: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },

        // [theme.fn.smallerThan("xs")]: {
        //   display: "none",
        // },
    },

    userActive: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },
}));

const AHeader = () => {
    const { classes, cx } = useStyles();
    const [userMenuOpened, setUserMenuOpened] = useState(false);

    const name = localStorage.getItem('name');

    //   const { data: session } = useSession();

    //   const router = useRouter();
    const { pathname } = useLocation();

    //   const user = {
    //     name: session?.user.name,
    //     image: "",
    //   };

    const [opened, { toggle }] = useDisclosure(false);

    const admin_phoneMenuListRef = useRef<HTMLDivElement>(null);
    const admin_burgerIconRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        admin_burgerIconRef.current?.addEventListener('click', () => {
            opened
                ? (admin_phoneMenuListRef.current!.style.transform = 'translate(-100%, 0)')
                : (admin_phoneMenuListRef.current!.style.transform = 'translate(0, 0)');
        });

        if (opened) {
            admin_phoneMenuListRef.current!.style.transform = 'translate(0, 0)';
        } else {
            admin_phoneMenuListRef.current!.style.transform = 'translate(-100%, 0)';
        }
    }, [opened]);

    return (
        <>
            <header className="hidden fixed lg:flex left-0 right-0 top-0 w-full h-fit min-h-[70px] bg-white text-[#5A6A85] z-40">
                <div className="w-full min-w-[270px] max-w-[270px]"></div>
                <div className="relative w-full py-5 px-6 flex justify-between items-center wrap">
                    <IconBellRinging size={21} />
                    <Menu
                        width={180}
                        position="bottom-end"
                        transitionProps={{ transition: 'pop-top-right' }}
                        onClose={() => setUserMenuOpened(false)}
                        onOpen={() => setUserMenuOpened(true)}
                        withinPortal
                    >
                        <Menu.Target>
                            <UnstyledButton
                                className={cx(classes.user, {
                                    [classes.userActive]: userMenuOpened,
                                })}
                            >
                                <div className="leading-3">
                                    <IconUserCircle
                                        size={30}
                                        stroke={1}
                                        fill="gray"
                                        fillOpacity={0.1}
                                        className="mx-auto"
                                    />
                                    <small className="text-xs">{name}</small>
                                </div>
                            </UnstyledButton>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => (window.location.href = '/admin/passwordChange')}>
                                Change password
                            </Menu.Item>
                            <Menu.Item
                            // onClick={() =>
                            //     signOut({
                            //         callbackUrl: '/',
                            //     })
                            // }
                            >
                                Logout
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </div>
            </header>
            <header className="fixed w-full h-fit left-0 top-0 flex justify-between items-center lg:hidden bg-white p-3 z-50">
                <Burger
                    ref={admin_burgerIconRef}
                    opened={opened}
                    onClick={toggle}
                    className="block lg:hidden"
                    size="md"
                />
                <div
                    onClick={() => (window.location.href = '/admin/dashboard')}
                    className="relative w-auto max-h-16 lg:max-h-24 mx-auto overflow-hidden"
                >
                    <img
                        className="!relative w-auto h-full mx-auto max-h-16 lg:max-h-24"
                        alt="logo"
                        src={'/images/logo.png'}
                        width={943}
                        height={119}
                    />
                </div>
                <Menu
                    width={180}
                    position="bottom-end"
                    transitionProps={{ transition: 'pop-top-right' }}
                    onClose={() => setUserMenuOpened(false)}
                    onOpen={() => setUserMenuOpened(true)}
                    withinPortal
                >
                    <Menu.Target>
                        <UnstyledButton
                            className={cx(classes.user, {
                                [classes.userActive]: userMenuOpened,
                            })}
                        >
                            <div className="leading-3">
                                <IconUserCircle
                                    size={30}
                                    stroke={1}
                                    fill="gray"
                                    fillOpacity={0.1}
                                    className="mx-auto"
                                />
                                <small className="text-xs">{name}</small>
                            </div>
                        </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item onClick={() => (window.location.href = '/admin/passwordChange')}>
                            Change password
                        </Menu.Item>
                        <Menu.Item
                        // onClick={() =>
                        //     signOut({
                        //         callbackUrl: '/',
                        //     })
                        // }
                        >
                            Logout
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </header>
            <div
                ref={admin_phoneMenuListRef}
                className="block lg:hidden bg-white px-5 fixed top-20 left-0 w-full h-full z-30 -translate-x-full transition-transform duration-200"
            >
                <div className="relative mt-12">
                    <small className="text-xs px-2">HOME</small>
                    <div
                        onClick={() => {
                            toggle();
                            window.location.href = '/admin/dashboard';
                        }}
                        className={
                            pathname === '/admin/dashboard'
                                ? 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 bg-point text-white rounded-xl transition-all duration-200'
                                : 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 hover:bg-point hover:bg-opacity-20 hover:text-adminMain rounded-xl  transition-all duration-200'
                        }
                    >
                        <IconLayoutDashboard size={22} stroke={1.5} />
                        <small className="text-sm">Dashboard</small>
                    </div>
                </div>
                <div className="relative my-5">
                    <small className="text-xs px-2">DATA</small>
                    {ADMIN_NAV_LIST.map((v, i) => (
                        <div
                            key={i}
                            onClick={() => {
                                toggle();
                                window.location.href = v.pathname;
                            }}
                            className={
                                pathname.includes(v.pathname)
                                    ? 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 bg-point text-white rounded-xl transition-all duration-200'
                                    : 'relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 hover:bg-point hover:bg-opacity-10 rounded-xl transition-all duration-200'
                            }
                        >
                            <v.icon size={22} stroke={1.5} />
                            <small className="text-sm">{v.title}</small>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AHeader;
