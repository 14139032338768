import NoticeCard from "components/sub/NoticeCard";

const BoardNotice = () => {
  return (
    <div className="relative w-full h-fit py-14 lg:py-28">
      <div className="relative w-full h-full wrap">
        <div className="lg:flex justify-between items-end">
          <h3>공지사항</h3>
          <div
            id="search"
            className="relative w-full h-fit max-w-xs my-5 lg:my-0"
          >
            {/* <input
                    type="text"
                    name="search"
                    id="search"
                    placeholder="검색할 키워드를 입력해주세요."
                    className="relative w-full border-b border-black py-2 px-1 text-base"
                />
                <button className="absolute right-0 bottom-2 w-fit h-fit bg-white">
                    <IconSearch />
                </button> */}
          </div>
        </div>
        <NoticeCard />
      </div>
    </div>
  );
};

export default BoardNotice;
