import axios from "axios";
import OneInquiry from "components/admin/inquiry/OneInquiry";
import { Inquiry } from "const/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const InquiryDetail = () => {
  const params = useParams();

  const [inquiry, setInquiry] = useState<Inquiry>();

  useEffect(() => {
    axios
      .get(`/api/inquiry/${params.id}`)
      .then((res) => setInquiry(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full h-full mb-5 border border-slate-100 bg-white shadow-md py-10 px-5 rounded-lg">
      {inquiry && <OneInquiry data={inquiry} />}
    </div>
  );
};

export default InquiryDetail;
