import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";

import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/core/styles.css";

import "swiper/css";
import "ckeditor5/ckeditor5.css";
import "react-datepicker/dist/react-datepicker.css";

import Layout from "components/common/Layout";
import Home from "page/Home";
import AboutCi from "page/(about)/ci";
import AboutGreetings from "page/(about)/greetings";
import AboutRule from "page/(about)/rule";
import AboutLocation from "page/(about)/location";
import Business from "page/(business)/business";
import ContactQnA from "page/(contact)/qna";
import ContactApplication from "page/(contact)/application";
import BoardNotice from "page/(board)/notice";
import BoardActivity from "page/(board)/activity";
import Login from "page/login";
import Dashboard from "page/admin/dashboard";
import InquiryList from "page/admin/inquiry";
import InquiryDetail from "page/admin/inquiry/[id]";
import Board from "page/admin/board";
import OneApplication from "page/admin/application/[id]/page";
import PostAndUpdateApplication from "page/admin/board/write";
import Rule from "page/admin/rule";
import QnA from "page/admin/qna";
import Application from "page/admin/application";
import BoardDetail from "page/(board)/notice/[id]";
import ActivityDetail from "page/(board)/activity/[id]";
import ApplicationDetail from "page/(contact)/application/[id]";
import Contribution from "page/contribution";

function App() {
  const vh_height = () => {
    var vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    vh_height();
    AOS.init({
      duration: 800,
    });

    if ((window.navigator as any).userAgentData) {
      if ((window.navigator as any).userAgentData.mobile) {
        localStorage.setItem("device", "mobile");
      } else {
        localStorage.setItem("device", "pc");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Notifications position="top-center" />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/ci" element={<AboutCi />} />
          <Route path="/greetings" element={<AboutGreetings />} />
          <Route path="/rule" element={<AboutRule />} />
          <Route path="/location" element={<AboutLocation />} />
          <Route path="/business" element={<Business />} />
          <Route path="/qna" element={<ContactQnA />} />
          <Route path="/application" element={<ContactApplication />} />
          <Route path="/application/:id" element={<ApplicationDetail />} />
          <Route path="/notice" element={<BoardNotice />} />
          <Route path="/notice/:id" element={<BoardDetail />} />
          <Route path="/activity" element={<BoardActivity />} />
          <Route path="/activity/:id" element={<ActivityDetail />} />
          <Route path="/contribution" element={<Contribution />} />

          <Route path="admin">
            <Route path="login" element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="inquiry" element={<InquiryList />} />
            <Route path="inquiry/:id" element={<InquiryDetail />} />
            <Route path="board" element={<Board />} />
            <Route path="board/:id" element={<OneApplication />} />
            <Route path="post" element={<PostAndUpdateApplication />} />
            <Route
              path="post/application"
              element={<PostAndUpdateApplication />}
            />
            <Route path="update/:id" element={<PostAndUpdateApplication />} />
            <Route path="application" element={<Application />} />
            <Route path="application/:id" element={<OneApplication />} />
            <Route
              path="application/post"
              element={<PostAndUpdateApplication />}
            />
            <Route
              path="application/update/:id"
              element={<PostAndUpdateApplication />}
            />
            <Route path="rule" element={<Rule />} />
            <Route path="qna" element={<QnA />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
