import style from "./BoardThumb.module.css";
import axios from "axios";

const BoardThumb = ({
  notice,
  setNotice,
}: {
  notice: string;
  setNotice: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const imageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios
        .post("/api/images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.code === "0000") return res.data.data;
        });

      setNotice(response.path + response.saveName);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={style.filebox + " bg-neutral-200 p-5"}>
      <p className="pl-2">
        <b>썸네일</b>
      </p>
      <input
        className="upload-name border h-fit py-2.5 px-2 my-2"
        value={notice || "썸네일"}
        placeholder="썸네일"
        readOnly
      />
      <label htmlFor="thumb">파일찾기</label>
      <input
        type="file"
        id="thumb"
        defaultValue={""}
        onChange={(e) => e.target.files && imageUpload(e.target.files[0])}
      />
    </div>
  );
};

export default BoardThumb;

export interface NoticeFileProps {
  file: File | null;
  name: string;
}

export const BoardFile = ({
  noticeFile,
  setNoticeFile,
}: {
  noticeFile: NoticeFileProps;
  setNoticeFile: React.Dispatch<React.SetStateAction<NoticeFileProps>>;
}) => {
  return (
    <div className={style.filebox + " bg-light p-5"}>
      <p className="pl-2">
        <b>첨부파일</b>
      </p>
      <input
        className="upload-name border h-fit py-2.5 px-2 my-2"
        value={(noticeFile && noticeFile.name) || "첨부파일"}
        placeholder="첨부파일"
        readOnly
      />
      <label htmlFor="file">파일찾기</label>
      <input
        type="file"
        accept=".pdf"
        id="file"
        defaultValue={""}
        onChange={(e) => {
          e.target.files &&
            setNoticeFile({
              name: e.target.files[0].name,
              file: e.target.files[0],
            });
        }}
      />
    </div>
  );
};
