import ApplicationList from "components/sub/ApplicationList";
import { SubTitle3 } from "components/sub/SubTitle";

const ContactApplication = () => {
  return (
    <div className=" relative w-full h-full">
      <SubTitle3
        src={"/images/subtitle5.jpg"}
        title="장학신청"
        sub="희우장학재단은 학생들이 학업에 전념할 수 있도록 장학금 사업을 진행하고 있습니다."
      />
      <div className="relative w-full h-fit py-14 lg:py-28">
        <div className="relative w-full h-full wrap">
          <ApplicationList />
        </div>
      </div>
    </div>
  );
};

export default ContactApplication;
