import { useState } from 'react';

import axios from 'axios';

import { notifications } from '@mantine/notifications';

import { Inquiry } from 'const/table';
import { IconCheck } from '@tabler/icons-react';
import PrivateText from './PrivateText';

const InquiryForm = () => {
    const [isPrivacyOk, setisPrivacyOk] = useState<boolean>(false);
    const [inquiryInfo, setInquiryInfo] = useState<Inquiry>({
        id: 0,
        name: '',
        phone: '',
        email: '',
        title: '',
        contents: '',
        device: '',
        ip: '',
        isCheck: 'N',
        createdAt: new Date(),
    });

    const postInquiry = async () => {
        if (!isPrivacyOk) {
            alert('개인정보 수집 동의가 필요합니다.');
            return false;
        }

        if (!inquiryInfo.name || !inquiryInfo.phone || !inquiryInfo.title || !inquiryInfo.contents) {
            alert('필수입력 항목을 모두 입력해주세요.');
            return false;
        }

        await axios
            .post('/api/inquiry', {
                name: inquiryInfo.name,
                email: inquiryInfo.email,
                phone: inquiryInfo.phone,
                title: inquiryInfo.title,
                contents: inquiryInfo.contents,
                device: sessionStorage.getItem('device'),
                ip: sessionStorage.getItem('ip'),
            })
            .then((res) => {
                if (res.data.code === '0000') {
                    notifications.show({
                        title: '문의 등록 완료',
                        message: '문의 등록이 완료되었습니다. 메인화면으로 이동합니다.',
                        autoClose: 2000,
                        color: 'blue',
                        icon: <IconCheck style={{ width: 'rem(18)', height: 'rem(18)' }} />,
                    });
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                }
            });
    };
    return (
        <div className="relative w-full h-full wrap">
            <div className="relative w-full h-fit">
                <div className="relative w-full h-fit flex gap-2 lg:gap-5 border rounded-md py-5 px-5">
                    <input
                        type="checkbox"
                        name="privaty"
                        id="privaty"
                        onChange={(e) => setisPrivacyOk(e.target.checked)}
                        className="relative w-5 h-5 outline-none!"
                    />
                    <label htmlFor="privaty">
                        <p className="text-base lg:text-lg">
                            비회원 개인정보 수집 및 이용에 관한 동의
                            <span className="text-point">(필수)</span>
                        </p>
                    </label>
                </div>
                <div className="bg-light relative w-full h-36 lg:h-72 overflow-y-scroll px-5">
                    <PrivateText />
                </div>
            </div>
            <div className="relative w-full h-full py-14">
                <div className="flex justify-between items-end">
                    <h5>기본정보 입력</h5>
                    <small>
                        <span className="text-point">*</span> 필수입력항목
                    </small>
                </div>
                <div className="relative w-full h-full my-5 py-7 border-t border-b">
                    <ul className="grid gap-7 lg:gap-0">
                        <li className="lg:flex">
                            <p className="th relative w-full lg:w-64 p-0 lg:py-9 lg:px-10 mb-3">
                                <span className="essential relative w-fit h-fit">
                                    성명
                                    <span className="absolute -right-1.5 lg:-right-3 -top-1.5 lg:-top-2.5 text-point">
                                        *
                                    </span>
                                </span>
                            </p>
                            <div className="td w-full lg:w-[calc(100% - 215px)] lg:py-5 lg:px-10 align-middle">
                                <div className="has_add_msg w-full inline-block">
                                    <div className="form_input relative w-full  inline-block align-middle">
                                        <input
                                            className="relative w-full lg:w-96 h-10 lg:h-16 border rounded-md px-5"
                                            type="text"
                                            name="nonName"
                                            title="성명"
                                            placeholder="성명"
                                            maxLength={10}
                                            value={inquiryInfo.name}
                                            onChange={(e) => setInquiryInfo({ ...inquiryInfo, name: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="lg:flex">
                            <p className="th relative w-full lg:w-64 p-0 lg:py-9 lg:px-10 mb-3">
                                <span className="essential relative w-fit h-fit">
                                    이메일
                                    <span className="absolute -right-1.5 lg:-right-3 -top-1.5 lg:-top-2.5 text-point">
                                        *
                                    </span>
                                </span>
                            </p>
                            <div className="td w-full lg:w-[calc(100% - 215px)] lg:py-5 lg:px-10 align-middle">
                                <div className="has_add_msg inline-block w-full">
                                    <div className="form_input relative w-full inline-block align-middle">
                                        <input
                                            className="relative w-full lg:w-96 h-10 lg:h-16 border rounded-md px-5 emailChk"
                                            type="text"
                                            name="nonEmail"
                                            title="이메일"
                                            placeholder="이메일"
                                            maxLength={50}
                                            value={inquiryInfo.email || ''}
                                            onChange={(e) =>
                                                setInquiryInfo({
                                                    ...inquiryInfo,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="lg:flex">
                            <p className="th relative w-full lg:w-64 p-0 lg:py-9 lg:px-10 mb-3">
                                <span className="essential relative w-fit h-fit">
                                    전화번호
                                    <span className="absolute -right-1.5 lg:-right-3 -top-1.5 lg:-top-2.5 text-point">
                                        *
                                    </span>
                                </span>
                            </p>
                            <div className="td w-full lg:w-[calc(100% - 215px)] lg:py-5 lg:px-10 align-middle">
                                <div className="has_add_msg inline-block w-full">
                                    <div className="form_input relative w-full inline-block align-middle">
                                        <input
                                            className="relative w-full lg:w-96 h-10 lg:h-16 border rounded-md px-5 emailChk"
                                            type="text"
                                            name="phone"
                                            title="전화번호"
                                            placeholder="전화번호"
                                            maxLength={11}
                                            value={inquiryInfo.phone}
                                            onChange={(e) =>
                                                setInquiryInfo({
                                                    ...inquiryInfo,
                                                    phone: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="relative w-full h-full pb-7 lg:py-14">
                <div className="flex justify-between items-end">
                    <h5>문의내용 입력</h5>
                    <small>
                        <span className="text-point">*</span> 필수입력항목
                    </small>
                </div>
                <div className="relative w-full h-full my-5 py-7 border-t border-b">
                    <ul className="grid gap-5 lg:gap-0">
                        <li className="lg:flex">
                            <p className="th relative w-full lg:w-64 p-0 lg:py-9 lg:px-10 mb-3">
                                <span className="essential relative w-fit h-fit">
                                    제목
                                    <span className="absolute -right-1.5 lg:-right-3 -top-1.5 lg:-top-2.5 text-point">
                                        *
                                    </span>
                                </span>
                            </p>
                            <div className="td w-full lg:w-[calc(100% - 215px)] lg:py-5 lg:px-10 align-middle">
                                <div className="has_add_msg w-full inline-block">
                                    <div className="form_input relative w-full  inline-block align-middle">
                                        <input
                                            className="relative w-full lg:w-96 h-10 lg:h-16 border rounded-md px-5"
                                            type="text"
                                            name="title"
                                            title="제목"
                                            placeholder="제목"
                                            value={inquiryInfo.title}
                                            onChange={(e) =>
                                                setInquiryInfo({
                                                    ...inquiryInfo,
                                                    title: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="lg:flex">
                            <p className="th relative w-full lg:w-64 p-0 lg:py-9 lg:px-10 mb-3">
                                <span className="essential relative w-fit h-fit">
                                    내용
                                    <span className="absolute -right-1.5 lg:-right-3 -top-1.5 lg:-top-2.5 text-point">
                                        *
                                    </span>
                                </span>
                            </p>
                            <div className="td w-full lg:w-[calc(100% - 215px)] lg:py-5 lg:px-10 align-middle">
                                <div className="has_add_msg inline-block w-full">
                                    <div className="form_input relative w-full inline-block align-middle">
                                        <textarea
                                            className="relative w-full lg:w-96 h-16 lg:h-28 py-5 border rounded-md px-5 resize-none"
                                            name="inquiry"
                                            title="내용"
                                            placeholder="내용"
                                            value={inquiryInfo.contents?.toString()}
                                            onChange={(e) =>
                                                setInquiryInfo({
                                                    ...inquiryInfo,
                                                    contents: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div
                    onClick={postInquiry}
                    className="relative w-fit min-w-[150px] h-fit border flex justify-center items-center mx-auto mt-7 lg:mt-14 py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
                >
                    <p>등록</p>
                </div>
            </div>
        </div>
    );
};

export default InquiryForm;
