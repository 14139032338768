import RuleUpload from "components/admin/rule/RuleUpload";

const Rule = () => {
  return (
    <div>
      <RuleUpload />
    </div>
  );
};

export default Rule;
