import { Link } from 'react-router-dom';

const ThreeBottom = () => {
    return (
        <div className="relative !px-0 lg:px-5 wrap grid grid-cols-3 justify-items-center my-5 lg:!my-10">
            <div className="relative w-full h-16 border-r lg:p-1">
                <Link to={'https://www.nts.go.kr/'} target="_blank">
                    <img
                        className="!relative w-auto h-full max-w-[75px] lg:max-w-none mx-auto object-contain cursor-pointer"
                        alt="important1"
                        src={'/images/important1.png'}
                        width={1000}
                        height={462}
                    />
                </Link>
            </div>
            <div className="relative w-full h-16 border-r lg:p-1">
                <Link to={'https://www.moe.go.kr/main.do'} target="_blank">
                    <img
                        className="!relative w-auto h-full max-w-[90px] lg:max-w-none mx-auto object-contain cursor-pointer"
                        alt="important2"
                        src={'/images/important2.png'}
                        width={1000}
                        height={462}
                    />
                </Link>
            </div>
            <div className="relative w-full h-16  lg:p-1">
                <Link to={'https://www.sen.go.kr/sen/index.do'} target="_blank">
                    <img
                        className="!relative w-auto h-full max-w-[95px] lg:max-w-none mx-auto object-contain cursor-pointer"
                        alt="important3"
                        src={'/images/important3.png'}
                        width={1000}
                        height={462}
                    />
                </Link>
            </div>
        </div>
    );
};

export default ThreeBottom;
