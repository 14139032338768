import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ThreeBottom from './ThreeBottom';

const Banner = () => {
    return (
        <div className="relative w-full h-fit">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 6500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    el: '.swiper-pagination',
                    clickable: false,
                    type: 'custom',
                    renderCustom: function (swiper: any, current: any, total: any) {
                        return (
                            '<div class="current">' +
                            0 +
                            current +
                            '</div>' +
                            '<span id="total" class="total">' +
                            0 +
                            (current > 2 ? 1 : current + 1) +
                            '</span>'
                        );
                    },
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                onBeforeInit={(swiper: any) => {
                    if (swiper.params.navigation) {
                        swiper.params.navigation.prevEl = document.querySelector('.swiper-button-prev');
                        swiper.params.navigation.nextEl = document.querySelector('.swiper-button-next');
                    }
                    swiper.navigation.update();
                }}
                onAutoplayTimeLeft={(s: any, time: any, progress: number) => {
                    const progressLine = document.querySelector<HTMLElement>('.svg');
                    progressLine!.style.setProperty('--progress', (1 - progress).toString());
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="bannerSwiper relative w-full h-fit bg-black text-white"
            >
                <SwiperSlide>
                    <BannerCard
                        id="banner1"
                        position="object-center"
                        src={'/images/banner1.jpg'}
                        title="아직은&nbsp;학교에&nbsp;남고싶은 기특한&nbsp;당신께,"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BannerCard
                        id="banner2"
                        position="object-center"
                        src={'/images/banner2.jpg'}
                        title="열심히&nbsp;사는&nbsp;것을 미안해&nbsp;할&nbsp;필요는&nbsp;없습니다."
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BannerCard
                        id="banner3"
                        position="object-bottom"
                        src={'/images/banner3.jpg'}
                        title="거봐요,&nbsp;걱정은&nbsp;우리가&nbsp;할테니 당신은&nbsp;열심히만&nbsp;하면 해&nbsp;낼&nbsp;수&nbsp;있다고&nbsp;했잖아요."
                    />
                </SwiperSlide>
                <div className="absolute left-0 bottom-5 w-full h-fit">
                    <div className="relative wrap flex items-center gap-5">
                        <div className={'progressbox grid items-center'}>
                            <div className="absolute flex justify-between bottom-0 left-0 w-full h-full">
                                <div className="swiper-pagination !static flex w-full h-full justify-between items-center"></div>
                            </div>
                            <div className={'autoplayprogress'}>
                                <svg className="svg" viewBox="0 0 100 10">
                                    <line x1="0" y1="0" x2="100" y2="0" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex w-fit h-fit items-center">
                            <div className={'swiperbuttonprev swiper-button-prev'}></div>
                            <div className={'swiperbuttonnext swiper-button-next'}></div>
                        </div>
                    </div>
                </div>
            </Swiper>
            <ThreeBottom />
        </div>
    );
};

export default Banner;

export const BannerCard = ({ id, position, src, title }: { id: string; position: string; src: any; title: string }) => {
    return (
        <div id={id} className="relative w-full h-[500px] lg:h-[73vh] lg:max-h-[900px] overflow-hidden">
            <img
                id="img"
                className={`!relative w-full h-full object-cover ${position} opacity-50`}
                alt="banner3"
                src={src}
                width={3000}
                height={3000}
            />
            <div className="absolute left-0 top-0 w-full h-full z-0">
                <div className="relative wrap w-full h-full grid items-center text-center">
                    <div>
                        <div className="title1 relative w-fit h-fit mx-auto opacity-0 translate-y-5">
                            {id === 'banner3' ? (
                                <p className={'font-serif text-xl lg:text-3xl'}>{title}</p>
                            ) : (
                                <p className={'font-serif  text-[22px] lg:text-4xl'}>{title}</p>
                            )}
                        </div>
                        {id === 'banner1' && (
                            <div className="title2 relative w-fit h-fit mx-auto opacity-0 translate-y-5">
                                <h2 className={'font-serif  font-medium text-3xl lg:text-5xl 2xl:text-6xl'}>
                                    마른 꿈에 <span className={'font-serif  text-point font-medium'}>단비</span>가 되어.
                                </h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
