import { notifications } from "@mantine/notifications";
import { IconCheck, IconLetterQ } from "@tabler/icons-react";
import axios from "axios";
import { QnA } from "const/table";
import { useState } from "react";

const QnAList = ({ qna }: { qna: QnA[] }) => {
  const [data, setdata] = useState<QnA[] | null>(qna);

  console.log(qna);

  const UpdateQnA = async (data: QnA) => {
    if (!data.title || !data.contents) return false;

    axios
      .put(`/api/qna`, {
        id: data.id,
        title: data.title,
        contents: data.contents,
      })
      .then((res) => {
        if (res.data.code === "0000") {
          notifications.show({
            title: "자주묻는 질문 수정 완료",
            message: "자주묻는 질문 수정이 완료되었습니다.",
            autoClose: 2000,
            color: "blue",
            icon: <IconCheck style={{ width: "rem(18)", height: "rem(18)" }} />,
          });
          setTimeout(() => {
            window.location.reload();
            window.location.href = "/admin/dashboard";
          }, 1000);
        }
      });
  };

  return (
    <div className="relative w-full h-full">
      {data &&
        data.map((v, i) => (
          <div key={i} className="relative w-full h-full p-5">
            <div className="relative w-full h-fit py-3 flex items-center gap-1">
              <IconLetterQ />
              <input
                type="text"
                className="relative w-full border px-2 py-3 rounded-md"
                value={v.title}
                onChange={(e) => {
                  let newData = data.slice();
                  newData[i].title = e.target.value;
                  setdata(newData);
                }}
              />
            </div>
            <div className="relative w-full h-fit">
              <textarea
                className="relative w-full h-24 border px-2 py-3 resize-none rounded-md"
                value={v.contents}
                onChange={(e) => {
                  let newData = data.slice();
                  newData[i].contents = e.target.value;
                  setdata(newData);
                }}
              />
            </div>
            <div className="relative w-full h-fit my-5">
              <div
                onClick={() => UpdateQnA(data[i])}
                className="relative w-fit h-fit py-1.5 px-10 border border-black mx-auto cursor-pointer hover:bg-black hover:text-white"
              >
                <p className="lg:text-base">수정완료</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default QnAList;
