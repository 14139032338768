import { IconArrowNarrowUp } from '@tabler/icons-react';

const MNav = () => {
    return (
        <div className="fixed bottom-16 right-[3%] w-fit h-fit z-50">
            <button
                onClick={() => (window.location.href = '/contribution')}
                className="hidden relative w-16 h-16 rounded-full bg-font text-white mb-3 lg:grid items-center hover:shadow-md hover:shadow-zinc-600 hover:-translate-y-2 transition-all duration-300"
            >
                <small>
                    기부
                    <br />
                    하기
                </small>
            </button>
            <button
                onClick={() => window.scrollTo(0, 0)}
                className="hidden relative w-16 h-16 border bg-white border-font rounded-full lg:grid items-center"
            >
                <IconArrowNarrowUp className="mx-auto" />
            </button>
        </div>
    );
};

export default MNav;
