import KakaoMap from 'components/sub/KakaoMap';
import SubTitle from 'components/sub/SubTitle';

const AboutLocation = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle src={'/images/subtitle1.jpg'} title="찾아오시는길" />
            <div className="relative w-full h-fit py-14 lg:py-28">
                <div className="relative w-full h-full wrap">
                    <div className="grid gap-10 mt-10 lg:mt-16 mb-16 lg:mb-20 ">
                        <div className="grid lg:grid-cols-2">
                            <div>
                                <p className="font-black mb-2">주소</p>
                                <p className="text-base text-neutral-500">서울특별시 종로구 창경궁로 264, 6층</p>
                            </div>
                            <div className="mt-5 lg:mt-0">
                                <p className="font-black mb-2">대표번호</p>
                                <p className="text-base text-neutral-500">02-747-1680</p>
                            </div>
                        </div>
                        <div>
                            <KakaoMap />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutLocation;
