import { Pagination } from "@mantine/core";
import axios from "axios";
import { dateFormat } from "const/dateFormat";
import { JoinNotice } from "const/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const NoticeCard = () => {
  const [categoryId, setCategoryId] = useState(0);
  const [total, setTotal] = useState(0);
  const [categoryTotal, setCategoryTotal] = useState(total);
  const [data, setdata] = useState<JoinNotice[] | null>(null);
  const [page, setPage] = useState(1);

  const [searchParams, setSeratchParams] = useSearchParams();
  const category = searchParams.get("categoryId");

  useEffect(() => {
    total < 0 &&
      axios
        .get(`/api/board/total?categoryId=${category || 0}`)
        .then((res) => setTotal(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(`/api/board/category/${categoryId}?page=${page - 1}&size=10`)
      .then((res) => setdata(res.data.data.content));
  }, [page, categoryId]);

  useEffect(() => {
    axios
      .get(`/api/board/total?categoryId=${categoryId}`)
      .then((res) => setCategoryTotal(res.data.data));
  }, [categoryId]);

  return (
    <div className="relative w-full h-full py-7 lg:py-14">
      <ul className="flex items-center gap-2 lg:gap-5 flex-wrap sm:flex-nowrap">
        <li className={categoryId === 0 ? "text-point" : "text-neutral-500"}>
          <p
            className="lg:text-lg cursor-pointer"
            onClick={() => setCategoryId(0)}
          >
            ALL
          </p>
        </li>
        <li className={categoryId === 1 ? "text-point" : "text-neutral-500"}>
          <p
            className="lg:text-lg cursor-pointer"
            onClick={() => setCategoryId(1)}
          >
            공지사항
          </p>
        </li>
        <li className={categoryId === 3 ? "text-point" : "text-neutral-500"}>
          <p
            className="lg:text-lg cursor-pointer"
            onClick={() => setCategoryId(3)}
          >
            기부금 모금액 및 활용실적
          </p>
        </li>
        <li className={categoryId === 4 ? "text-point" : "text-neutral-500"}>
          <p
            className="lg:text-lg cursor-pointer"
            onClick={() => setCategoryId(4)}
          >
            기타
          </p>
        </li>
      </ul>
      <div className="relative w-full h-fit mt-5 border-t">
        <ul>
          {data &&
            data.map((v: any, i: number) => (
              <li
                key={v.category.name! + i}
                onClick={() => (window.location.href = `/notice/${v.id}`)}
                className="borderLine relative w-full h-fit py-5 px-2 border-b grid gap-5 cursor-pointer"
              >
                <span className="relative w-full h-fit flex justify-between items-start">
                  <p>
                    <b>{v.title}</b>
                  </p>
                  <p className="lg:text-sm text-neutral-500">
                    {dateFormat(v.createdAt)}
                  </p>
                </span>
                <small>{v.category.name}</small>
              </li>
            ))}
        </ul>
      </div>
      <div className=" relative w-fit h-fit mx-auto mt-10">
        <Pagination
          total={
            Math.ceil(categoryTotal / 10) ? Math.ceil(categoryTotal / 10) : 1
          }
          value={page}
          onChange={setPage}
          color="gray"
        />
      </div>
    </div>
  );
};

export default NoticeCard;
