import { useEffect, useState } from "react";

import { Pagination } from "@mantine/core";
import { dateFormat } from "const/dateFormat";
import axios from "axios";

const ActivityCard = () => {
  const [data, setData] = useState<any[] | null>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    axios
      .get(`/api/board/category/2?page=${page - 1}&size=10`)
      .then((res) => setData(res.data.data.content));
  }, [page]);

  useEffect(() => {
    total < 0 &&
      axios
        .get("/api/board/total?categoryId=2")
        .then((res) => setTotal(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full h-fit mt-5  ">
      <ul className="relative w-full grid grid-cols-2 lg:grid-cols-4 lg:gap-5">
        {data &&
          data.map((v, i) => (
            <li
              key={v.categoryName! + i}
              onClick={() => (window.location.href = `/activity/${v.id}`)}
              className="borderLine relative w-full h-fit py-5 px-2 border-b grid gap-5 cursor-pointer"
            >
              <div className="relative w-full h-52 lg:h-64 overflow-hidden">
                <img
                  id="activity_image"
                  className="!relative w-full h-full object-cover object-center"
                  alt="tempImage"
                  src={v.thumb}
                  width={500}
                  height={500}
                />
              </div>
              <span className="relative w-full h-fit flex justify-between items-start">
                <p>
                  <b>{v.title}</b>
                </p>
              </span>
              <p className="lg:text-sm text-neutral-500">
                {dateFormat(v.createdAt)}
              </p>
              {/* <small>{v.categoryName}</small> */}
            </li>
          ))}
      </ul>
      <div className=" relative w-fit h-fit mx-auto mt-10">
        <Pagination
          total={Math.ceil(total / 10) ? Math.ceil(total / 10) : 1}
          value={page}
          onChange={setPage}
          color="gray"
        />
      </div>
    </div>
  );
};

export default ActivityCard;
