import { useEffect } from "react";
import axios from "axios";
import Post from "components/admin/board/Post";

const PostAndUpdateApplication = () => {
  useEffect(() => {
    axios.delete("/api/images/init").then((res) => {
      if (res.data.code === "0000") {
        console.log("초기화 완료");
      }
    });
  }, []);

  return (
    <div className=" relative w-full h-fit">
      <Post />
    </div>
  );
};

export default PostAndUpdateApplication;
