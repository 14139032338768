import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import style from './BoardThumb.module.css';

import { IconCalendarCheck, IconCheck } from '@tabler/icons-react';

import { category } from 'const/category';
import Editor from '../Editor';
import BoardThumb from './BoardThumb';
import { notifications } from '@mantine/notifications';

const Post = () => {
    const params = useParams();

    const [title, setTitle] = useState<string>('');
    const [contents, setContents] = useState<string>('<p> </p>');
    const [categoryId, setcategoryId] = useState<number>(1);
    const [thumb, setThumb] = useState<string>('');
    const [file, setFile] = useState<{ name: string; file: File | null }>({
        name: '',
        file: null,
    });
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === '/admin/post/application') {
            setcategoryId(5);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(params.id);
        params.id &&
            axios.get(`/api/board/${params.id}`).then((res) => {
                console.log(res.data.data);
                setTitle(res.data.data.title);
                setContents(res.data.data.contents);
                setcategoryId(res.data.data.category.id);
                setThumb(res.data.data.thumb);
                setFile({
                    name: res.data.data.files[0].originalName || '',
                    file: null,
                });
                setStartDate(new Date(res.data.data.startDate || new Date()));
                setEndDate(new Date(res.data.data.endDate || new Date()));
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let today = new Date();

    const postBoard = async () => {
        if (!title || !contents) return false;

        const formData = new FormData();

        formData.append('title', title);
        formData.append('contents', contents);
        formData.append('categoryId', categoryId.toString());
        categoryId === 5 && formData.append('startDate', startDate.toISOString().slice(0, 10) + 'T00:00:00Z');
        categoryId === 5 && formData.append('endDate', endDate.toISOString().slice(0, 10) + 'T23:59:59Z');
        formData.append('thumb', thumb);

        file.file && formData.append('file', file.file);

        await axios
            .post('/api/board', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                if (res.data.code === '0000') {
                    notifications.show({
                        title: '게시글 등록 완료',
                        message: '게시글 등록이 완료되었습니다. 메인화면으로 이동합니다.',
                        autoClose: 2000,
                        color: 'blue',
                        icon: <IconCheck style={{ width: 'rem(18)', height: 'rem(18)' }} />,
                    });
                    setTimeout(() => {
                        window.location.href = '/admin/dashboard';
                    }, 1000);
                }
            });
    };

    const updateBoard = async () => {
        if (!title || !contents) return false;

        const formData = new FormData();

        formData.append('title', title);
        formData.append('contents', contents);
        formData.append('categoryId', categoryId.toString());
        categoryId === 5 && formData.append('startDate', startDate.toISOString().slice(0, 10) + 'T00:00:00Z');
        categoryId === 5 && formData.append('endDate', endDate.toISOString().slice(0, 10) + 'T23:59:59Z');
        formData.append('thumb', thumb);

        file.file && formData.append('file', file.file);

        await axios
            .put(`/api/board/${params?.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                if (res.data.code === '0000') {
                    notifications.show({
                        title: '게시글 수정 완료',
                        message: '게시글 수정이 완료되었습니다. 메인화면으로 이동합니다.',
                        autoClose: 2000,
                        color: 'blue',
                        icon: <IconCheck style={{ width: 'rem(18)', height: 'rem(18)' }} />,
                    });
                    setTimeout(() => {
                        window.location.href = '/admin/dashboard';
                    }, 1000);
                }
            });
    };

    return (
        <div className="relative w-full h-full grid gap-5">
            {categoryId !== 5 && (
                <div id="category" className="relative w-full h-fit grid gap-2 pl-2">
                    <p>
                        <b>카테고리</b>
                    </p>
                    <ul className="flex gap-3 flex-wrap lg:flex-nowrap lg:gap-5">
                        {category.map((v, i) => (
                            <li key={i} className="cursor-pointer">
                                <p
                                    onClick={() => setcategoryId(v.id)}
                                    className={categoryId === v.id ? 'text-point' : 'text-neutral-500'}
                                >
                                    {v.name}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div id="title" className="relative w-full h-fit grid gap-2">
                <label htmlFor="title" className="pl-2">
                    <p>
                        <b>제목</b>
                    </p>
                </label>
                <input
                    type="text"
                    placeholder="제목을 입력해주세요."
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="relative w-full border font-normal text-lg px-6 py-2 rounded-sm"
                />
            </div>
            {categoryId === 5 && (
                <div id="date-priod" className="relative w-full h-fit grid grid-cols-2 items-center gap-5 z-30">
                    <div className="relative w-full h-fit">
                        <p className="pl-2 mb-2">
                            <b>시작일</b>
                        </p>
                        <div className="flex justify-between items-center border p-3">
                            <DatePicker
                                className="relative w-full h-fit"
                                id="startdate"
                                dateFormat={'yyyy-MM-dd'}
                                placeholderText="YYYY-MM-DD"
                                minDate={today}
                                selected={startDate!}
                                onChange={(e) => setStartDate(e || today)}
                            />
                            <label htmlFor="startdate">
                                <IconCalendarCheck />
                            </label>
                        </div>
                    </div>
                    <div className="relative w-full h-fit">
                        <p className="pl-2 mb-2">
                            <b>종료일</b>
                        </p>
                        <div className="flex justify-between items-center border p-3">
                            <DatePicker
                                className="relative w-full h-fit"
                                id="enddate"
                                dateFormat={'yyyy-MM-dd'}
                                placeholderText="YYYY-MM-DD"
                                minDate={startDate!}
                                selected={endDate!}
                                onChange={(e) => setEndDate(e || today)}
                            />
                            <label htmlFor="enddate">
                                <IconCalendarCheck />
                            </label>
                        </div>
                    </div>
                </div>
            )}
            <div id="contents" className=" relative w-full h-full">
                <Editor data={contents} setData={setContents} />
            </div>
            <div id="upload" className="relative w-full h-fit grid grid-cols-2">
                <div className={style.filebox + ' bg-light p-5'}>
                    <p className="pl-2">
                        <b>첨부파일</b>
                    </p>
                    <input
                        className="upload-name border h-fit py-2.5 px-2 my-2"
                        value={(file && file.name) || '첨부파일'}
                        placeholder="첨부파일"
                        readOnly
                    />
                    <label htmlFor="file">파일찾기</label>
                    <input
                        type="file"
                        id="file"
                        defaultValue={''}
                        onChange={(e) => {
                            e.target.files &&
                                setFile({
                                    name: e.target.files[0].name,
                                    file: e.target.files[0],
                                });
                        }}
                    />
                </div>
                {categoryId === 2 && <BoardThumb notice={thumb} setNotice={setThumb} />}
            </div>
            {params.id ? (
                <div
                    onClick={updateBoard}
                    className="relative w-fit h-fit border flex justify-center items-center mx-auto mt-7 lg:mt-14 py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
                >
                    <p>게시물 수정</p>
                </div>
            ) : (
                <div
                    onClick={postBoard}
                    className="relative w-fit h-fit border flex justify-center items-center mx-auto mt-7 lg:mt-14 py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
                >
                    <p>게시물 업로드</p>
                </div>
            )}
        </div>
    );
};

export default Post;

export const uploadPlugin = (editor: any) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return customUploadAdapter(loader);
    };
};

export const customUploadAdapter = (loader: { file: Promise<any> }) => {
    return {
        upload() {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                loader.file.then((file) => {
                    formData.append('file', file);

                    axios
                        .post('/api/images', formData)
                        .then((res) => {
                            resolve({
                                default: res.data.data.path + res.data.data.saveName,
                            });
                        })
                        .catch((err) => reject(err));
                });
            });
        },
    };
};
