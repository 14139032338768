import React, { SetStateAction } from "react";

const BoardCategory = ({
  categoryId,
  setCategoryId,
}: {
  categoryId: number;
  setCategoryId: React.Dispatch<SetStateAction<number>>;
}) => {
  const main_all_category = [
    { id: 0, name: "ALL" },
    { id: 1, name: "공지사항" },
    { id: 2, name: "재단활동" },
    { id: 3, name: "기부금 모금액 및 활용실적" },
    { id: 4, name: "기타" },
  ];
  return (
    <ul className="flex items-center gap-5 flex-wrap lg:flex-nowrap">
      {main_all_category.map((v, i) => (
        <li key={i} className="cursor-pointer">
          <p
            onClick={() => setCategoryId(v.id)}
            className={
              categoryId === v.id
                ? "text-point lg:text-lg"
                : "text-neutral-500 lg:text-lg"
            }
          >
            {v.name}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default BoardCategory;
