import { File } from "const/table";
import { useEffect, useState } from "react";
import { NoticeFileProps } from "../board/BoardThumb";
import FileUpload from "./FileUpload";
import { IconCheck, IconTrash } from "@tabler/icons-react";
import axios from "axios";
import { notifications } from "@mantine/notifications";

const RuleUpload = () => {
  const [data, setData] = useState<File>();

  const [file, setFile] = useState<NoticeFileProps>({
    file: null,
    name: "",
  });

  useEffect(() => {
    axios.get(`/api/rule`).then((res) => {
      setData(res.data.data[0]);
      setFile({
        file: null,
        name: res.data.data[0]?.originalName || "",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postRule = async () => {
    if (!file.file) return false;

    const formData = new FormData();
    formData.append("file", file.file);

    await axios
      .post("/api/rule", formData)
      .then((res) => {
        if (res.data.code === "0000") {
          notifications.show({
            title: "정관 등록 완료",
            message: "정관 등록이 완료되었습니다.",
            autoClose: 2000,
            color: "blue",
            icon: <IconCheck style={{ width: "rem(18)", height: "rem(18)" }} />,
          });
          setTimeout(() => {
            window.location.href = "/admin/rule";
          }, 1000);
        }
      })
      .catch((error) => {
        window.alert("정관 등록 실패: " + error.message);
      });
  };
  const deleteRule = async () => {
    if (!window.confirm("정말로 정관을 삭제하시겠습니까?")) return false;

    await axios
      .delete(`/api/rule/${data?.id}`)
      .then((res) => {
        if (res.data.code === "0000") {
          notifications.show({
            title: "정관 삭제 완료",
            message: "정관 삭제가 완료되었습니다.",
            autoClose: 2000,
            color: "blue",
            icon: <IconCheck style={{ width: "rem(18)", height: "rem(18)" }} />,
          });
          setTimeout(() => {
            window.location.href = "/admin/rule";
          }, 1000);
        }
      })
      .catch((error) => {
        window.alert("정관 삭제 실패: " + error.message);
      });
  };
  return (
    <div className="relative w-full h-full text-center">
      <FileUpload file={file} setFile={setFile} />
      {data && data.originalName && (
        <div className="relative flex justify-between items-center max-w-xs mx-auto mt-3">
          <p className="lg:text-base">{data.originalName}</p>
          <button onClick={deleteRule}>
            <IconTrash />
          </button>
        </div>
      )}
      <div
        onClick={postRule}
        className="relative w-fit h-fit mt-10 mx-auto py-1.5 px-10 border border-black rounded-sm hover:bg-black hover:text-white cursor-pointer"
      >
        <p className="lg:text-base">정관 등록</p>
      </div>
    </div>
  );
};

export default RuleUpload;
