const PrivateText = () => {
    return (
        <div className="relative w-full h-fit py-14 lg:py-28">
            <div className="relative w-full h-full wrap leading-loose">
                <p>
                    <b>1. 개인 정보의 이용 목적</b>
                </p>
                <small>
                    여러분이 제공해주신 개인정보는 보다 정확한 서비스를 제공하기 위해 이용되며, 이용약관에 명시된 목적
                    이외에 다른 용도로 이용하거나 본인의 승낙 없이 제3자에게 회원정보를 제공하지 않습니다.
                    <br />
                    다만, 다음 각 사항에 해당하는 경우에는 예외로 제3자와 공유될 수 있습니다. <br />
                    <br />
                    1) 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구가 있을 경우
                    <br />
                    2) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우 <br />
                    <br />
                    개인정보를 새로이 제3자에게 제공하는 경우에는 제공 받는 자, 제공목적 및 제공할 정보의 내용을 이메일
                    등을 통해 당사자에게 미리 고지하여 동의를 얻으며, 당사자의 동의 없이 어떠한 정보도 제공하지
                    않습니다.
                    <br />
                    <br />
                </small>
                <p>
                    <b>2. 개인정보 수집항목 및 방법</b>
                </p>
                <small>
                    여러분이 제공해주신 개인정보는 보다 정확한 서비스를 제공하기 위해 이용되며, 이용약관에 명시된 목적
                    이외에 다른 용도로 이용하거나 본인의 승낙 없이 제3자에게 회원정보를 제공하지 않습니다. <br />
                    다만, 다음 각 사항에 해당하는 경우에는 예외로 제3자와 공유될 수 있습니다. <br />
                    <br />
                    1) 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구가 있을 경우 2) 기타 관계법령에서 정한
                    절차에 따른 요청이 있는 경우
                    <br />
                    <br /> 개인정보를 새로이 제3자에게 제공하는 경우에는 제공 받는 자, 제공목적 및 제공할 정보의 내용을
                    이메일 등을 통해 당사자에게 미리 고지하여 동의를 얻으며, 당사자의 동의 없이 어떠한 정보도 제공하지
                    않습니다.
                </small>
            </div>
        </div>
    );
};

export default PrivateText;
