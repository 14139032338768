import { Pagination } from '@mantine/core';
import { IconDeviceDesktop, IconDeviceMobile, IconLetterNSmall } from '@tabler/icons-react';
import axios from 'axios';
import { dateFormat } from 'const/dateFormat';
import { Inquiry } from 'const/table';
import { useEffect, useState } from 'react';

const Inquiries = () => {
    const [InquiryList, setInquiryList] = useState<Inquiry[] | null>(null);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState(1);

    useEffect(() => {
        total < 1 && axios.get(`/api/inquiry/total`).then((res) => setTotal(res.data.data.total));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        axios.get(`/api/inquiry/list?page=${page - 1}&size=10`).then((res) => {
            if (res.data.code === '0000') {
                setInquiryList(res.data.data.content);
            }
        });
    }, [page]);

    return (
        <div className="relative w-full h-full">
            <div className="relative w-full h-fit lg:px-5 text-center flex items-center py-3 border-b text-sm lg:text-base">
                <div className="relative w-1/12">No</div>
                <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    이름
                </div>
                <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    전화번호
                </div>
                <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    이메일
                </div>
                <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    제목
                </div>
                <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    내용
                </div>
                <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    사용기기
                </div>
                <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                    작성일
                </div>
            </div>
            {InquiryList &&
                InquiryList.map((v, i) => (
                    <div
                        key={i}
                        onClick={() => (window.location.href = `/admin/inquiry/${v.id}`)}
                        className="relative w-full h-fit lg:px-5 text-center flex items-center py-3 border-b hover:bg-light cursor-pointer  text-sm lg:text-base"
                    >
                        {v.isCheck === 'N' ? (
                            <div className="relative min-w-[25px]">
                                <IconLetterNSmall size={28} className="mx-auto text-point" />
                            </div>
                        ) : (
                            <div className="relative min-w-[25px]">
                                <small className="mx-auto">{i + 1}</small>
                            </div>
                        )}
                        <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                            {v.name}
                        </div>
                        <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                            {v.phone.slice(0, 3) + '-' + v.phone.slice(3, 7) + '-' + v.phone.slice(7)}
                        </div>
                        <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                            {v.email}
                        </div>
                        <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                            {v.title}
                        </div>
                        <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                            {v.contents}
                        </div>
                        <div className="hidden lg:block relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                            {v.device === 'phone' ? (
                                <IconDeviceMobile className="mx-auto" />
                            ) : (
                                <IconDeviceDesktop className="mx-auto" />
                            )}
                        </div>
                        <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all text-xs lg:text-base">
                            {dateFormat(v.createdAt)}
                        </div>
                    </div>
                ))}

            <div className=" relative w-fit h-fit mx-auto mt-10">
                {total > 0 && <Pagination total={Math.ceil(total / 10)} value={page} onChange={setPage} color="gray" />}
            </div>
        </div>
    );
};

export default Inquiries;
