import Inquiries from "components/admin/inquiry/Inquiries";
import ThreeInquiry from "components/admin/inquiry/ThreeInquiry";

const InquiryList = () => {
  return (
    <div>
      <ThreeInquiry />
      <Inquiries />
    </div>
  );
};

export default InquiryList;
