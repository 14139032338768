import { useEffect, useState } from "react";
import axios from "axios";
import { Pagination } from "@mantine/core";
import { IconCalendarSmile } from "@tabler/icons-react";
import { dateFormat, dateToDate } from "const/dateFormat";

const AdminApplicationList = () => {
  const [data, setData] = useState<any[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    !data &&
      axios
        .get(`/api/board/category/5?page=0&size=10`)
        .then((res) => setData(res.data.data.content));

    total < 1 &&
      axios
        .get(`/api/board/total?categoryId=5`)
        .then((res) => setTotal(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(`/api/board/category/5?page=${page - 1}&size=10`)
      .then((res) => setData(res.data.data.content));
  }, [page]);

  return (
    <div className="relative w-full h-full">
      <div className="flex justify-between items-center flex-wrap lg:flex-nowrap">
        <div></div>
        <div
          onClick={() => (window.location.href = `/admin/post/application`)}
          className="ml-auto mt-5 lg:mb-0 lg:ml-0 relative w-fit h-fit border flex justify-center items-center py-1 px-5 rounded-full border-black bg-black hover:bg-white text-white hover:text-black transition-colors duration-300 cursor-pointer"
        >
          <p>게시글 작성</p>
        </div>
      </div>
      <ul className="relative w-full h-full grid grid-cols-2 gap-5">
        {data &&
          data.map((v, i) => (
            <div
              key={i}
              onClick={() =>
                (window.location.href = `/admin/application/${v.id}`)
              }
              className="relative w-full h-full border rounded-md p-5 lg:p-10 2xl:p-14 cursor-pointer"
            >
              <div className="flex gap-5">
                <p className="lg:text-base">
                  <b>장학생</b>
                </p>
                <p
                  className={
                    dateToDate(v.startDate!, v.endDate!) === "진행중"
                      ? "lg:text-base text-point"
                      : "lg:text-base text-neutral-500"
                  }
                >
                  <b>{dateToDate(v.startDate!, v.endDate!)}</b>
                </p>
              </div>
              <div className="my-5 lg:my-10">
                <h5 className="lg:text-3xl relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all">
                  {v.title}
                </h5>
              </div>
              <div className="flex gap-5 lg:gap-10 items-center">
                <p>신청기간</p>

                <div className="flex gap-2">
                  <IconCalendarSmile />
                  <p>
                    {dateFormat(v.startDate!)} ~ {dateFormat(v.endDate!)}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </ul>
      <div className=" relative w-fit h-fit mx-auto mt-10">
        <Pagination
          total={Math.ceil(total / 10) ? Math.ceil(total / 10) : 1}
          value={page}
          onChange={setPage}
          color="gray"
        />
      </div>
    </div>
  );
};

export default AdminApplicationList;
