import { IconAlertCircle, IconArrowNarrowLeft } from "@tabler/icons-react";
import axios from "axios";
import { dateFormat } from "const/dateFormat";
import { File, JoinNotice } from "const/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const OneBoard = () => {
  const params = useParams();
  const [data, setData] = useState<JoinNotice | null>(null);

  useEffect(() => {
    params.id &&
      axios.get(`/api/board/${params.id}`).then((res) => {
        setData(res.data.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="relative w-full h-full wrap">
      <div
        onClick={() => window.history.back()}
        className="relative w-fit h-fit flex items-center gap-2 cursor-pointer"
      >
        <IconArrowNarrowLeft />
        <p>이전으로</p>
      </div>
      {data && (
        <div className="relative w-full h-fulll pt-7 lg:pt-14">
          <div className="relative w-full h-full max-w-4xl mx-auto">
            <div className="relative w-full grid gap-5 border-b pt-4 lg:pb-7">
              <div className="relative w-fit h-fit border border-font rounded-full py-0.5 px-3">
                <p className="text-xs lg:text-sm text-neutral-500">
                  {data.category.name}
                </p>
              </div>
              <h5 className="lg:text-4xl">{data.title}</h5>
              <small className="text-neutral-500">
                {dateFormat(data.createdAt)}
              </small>
            </div>
            {data.startDate && (
              <div className="relative w-full flex gap-1 items-center py-5">
                <IconAlertCircle className="text-point" />
                <p>신청기간</p>&nbsp;&nbsp;
                <p>
                  <b>{dateFormat(data.startDate)}</b>
                </p>
                <p>
                  <b>~</b>
                </p>
                <p>
                  <b>{dateFormat(data.endDate!)}</b>
                </p>
              </div>
            )}
            <div className="boardContents py-4 lg:py-7 border-b">
              <div
                className="min-h-[550px]"
                dangerouslySetInnerHTML={{
                  __html: data.contents.replaceAll("&lsquo;", "'"),
                }}
              ></div>
              {data.files[0] && (
                <a
                  href={`/api/board/download?id=${data.files[0].id}`}
                  className="relative w-full h-fit"
                  download
                >
                  <div className="relative w-full h-fit border p-3 shadow-sm rounded-sm lg:hover:shadow-md">
                    <p className="lg:text-lg">{data.files[0].originalName}</p>
                    <small className="lg:text-sm text-neutral-500">
                      DATE : {dateFormat(data.createdAt)}
                    </small>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OneBoard;
