const SubTitle = ({ src, title }: { src: any; title: string }) => {
    return (
        <div className="relative w-full h-fit">
            <div className="relative w-full h-fit overflow-hidden">
                <div className={'bg'}>
                    <div className={'img_area'}>
                        <img
                            className="!relative w-full h-full object-cover object-center"
                            alt="title"
                            src={src}
                            width={2000}
                            height={1333}
                        />
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" className="relative w-full h-fit wrap !-mt-7 lg:!-mt-14 z-10">
                <h3>{title}</h3>
            </div>
        </div>
    );
};

export default SubTitle;

export const SubTitle2 = () => {
    return (
        <div className={'subCard relative w-full h-fit'}>
            <div className="absolute left-0 top-5 lg:top-10 w-full h-fit">
                <div data-aos="fade-up" className="relative w-full h-fit wrap z-10">
                    <h3>희우장학재단의</h3>
                    <h3>단비같은 사업을 안내해드립니다.</h3>

                    <p className="mt-5">
                        희우장학재단은 초, 중, 고, 대학생을 대상으로 <br className="block lg:hidden" /> 꿈을 이어갈 수
                        있는 발판을 만들어주고자 합니다.
                    </p>
                </div>
            </div>
            <div className="relative w-full h-fit overflow-hidden">
                <div className={'bg2'}>
                    <div className={'img_area'}>
                        <img
                            className="!relative w-full h-full object-cover object-center"
                            alt="title"
                            src={'/images/subtitle3.jpg'}
                            width={2000}
                            height={1333}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SubTitle3 = ({ src, title, sub }: { src: any; title: string; sub: string }) => {
    return (
        <div className="relative w-full h-fit">
            <div className="relative w-full h-fit overflow-hidden">
                <div className={'bg'}>
                    <div className={'img_area'}>
                        <img
                            className="!relative w-full h-full object-cover object-bottom"
                            alt="title"
                            src={src}
                            width={2000}
                            height={1333}
                        />
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" className="relative w-full h-fit wrap !-mt-7 lg:!-mt-14 z-10">
                <div className="grid gap-3">
                    <h3>{title}</h3>
                    <p className="lg:text-lg text-neutral-500">{sub}</p>
                </div>
            </div>
        </div>
    );
};
