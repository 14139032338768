export const dateFormat = (beforDate: Date | string) => {
    let date = new Date(beforDate);
    let format =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());

    return format;
};

export const dateToDate = (startdate: Date | string, enddate: Date | string) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let start = new Date(startdate);
    start.setHours(0, 0, 0, 0);
    let end = new Date(enddate);
    end.setHours(0, 0, 0, 0);

    // console.log("today", today);
    // console.log("start", start);
    // console.log("end", end);

    if (today >= start && today <= end) {
        return '진행중';
    }

    if (today < start) {
        return '진행예정';
    }

    if (today > end) {
        return '종료';
    }
};
