import { IconPinned, IconBrandTelegram, IconUserQuestion, IconMessages, IconLicense } from '@tabler/icons-react';

export const headerLink = [
    {
        title: '재단소개',
        link: '/greetings',
        sub: [
            { title: '인사말', link: '/greetings' },
            { title: '정관', link: '/rule' },
            { title: '재단 CI', link: '/ci' },
            { title: '찾아오시는길', link: '/location' },
        ],
    },
    { title: '재단사업', link: '/business' },
    {
        title: '재단이야기',
        link: '/notice',
        sub: [
            { title: '공지사항', link: '/notice' },
            { title: '재단활동', link: '/activity' },
        ],
    },
    {
        title: '참여하기',
        link: '/qna',
        sub: [
            { title: '문의하기', link: '/qna' },
            { title: '장학신청', link: '/application' },
        ],
    },
    {
        title: '기부문화',
        link: '/contribution',
        sub: [{ title: '기부안내', link: '/contribution' }],
    },
    {
        title: '공익위반신고',
        link: 'https://www.nts.go.kr/nts/cm/cntnts/cntntsView.do?mi=13386&cntntsId=109155',
    },
];

export const ADMIN_NAV_LIST = [
    {
        id: 1,
        title: '게시판',
        pathname: '/admin/board',
        icon: IconPinned,
    },
    {
        id: 2,
        title: '장학신청',
        pathname: '/admin/application',
        icon: IconBrandTelegram,
    },
    {
        id: 3,
        title: '고객문의',
        pathname: '/admin/inquiry',
        icon: IconUserQuestion,
    },
    {
        id: 4,
        title: 'Q&A',
        pathname: '/admin/qna',
        icon: IconMessages,
    },
    {
        id: 5,
        title: '정관',
        pathname: '/admin/rule',
        icon: IconLicense,
    },
    // {
    //   id: 6,
    //   title: "관리자",
    //   pathname: "/admin/user",
    //   icon: IconUsers,
    // },
];
